/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

body {
  background-color: #5291bb;
  font-size: 1.5rem;
  font-family: "Archivo Narrow", sans-serif; }
  @media only screen and (max-width: 400px) {
    body {
      font-size: 1.2rem; } }

.container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 30px 15px 90px; }

h2 {
  font-size: 2rem;
  color: #800000;
  font-family: "Yanone Kaffeesatz", sans-serif;
  padding: 15px; }
  @media only screen and (max-width: 400px) {
    h2 {
      font-size: 1.5rem;
      padding: 7.5px; } }

h3 {
  padding: 15px; }

.four-oh-four {
  text-align: center;
  padding-top: 50px; }

.rounds-container {
  display: flex;
  align-items: space-evenly;
  justify-content: center;
  margin-top: 50px; }
  @media only screen and (max-width: 1090px) {
    .rounds-container {
      display: block;
      margin: 0px; } }

.round-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  @media only screen and (max-width: 1090px) {
    .round-container {
      align-items: center; } }

.list-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start; }

header {
  background-color: #003366;
  text-align: center;
  padding: 15px;
  font-family: "Yanone Kaffeesatz", sans-serif;
  color: #f5f5f5;
  font-size: 2rem;
  border-bottom: 15px solid #f5f5f5; }
  @media only screen and (max-width: 400px) {
    header {
      font-size: 1.5rem;
      padding: 7.5px; } }

footer {
  background-color: #003366;
  text-align: center;
  padding: 15px;
  font-family: "Yanone Kaffeesatz", sans-serif;
  color: #f5f5f5;
  font-size: 1rem;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px; }
  footer a {
    text-decoration: none;
    color: #f5f5f5; }
    footer a:hover {
      color: #f5f5f5;
      text-decoration: underline; }

input {
  width: 300px;
  font-size: 1.5rem;
  font-family: "Archivo Narrow", sans-serif;
  padding: 5px;
  border: none;
  border-radius: 5px; }
  input:focus {
    outline: none; }
  @media only screen and (max-width: 400px) {
    input {
      width: 95%; } }

.input-edit {
  border-radius: 0px; }
  @media only screen and (max-width: 400px) {
    .input-edit {
      width: 300px; } }

.error {
  color: #800000; }

.error-edit {
  background-color: #800000;
  color: #f5f5f5;
  padding: 5px; }

.error-add {
  color: #800000; }

button {
  font-family: "Archivo Narrow", sans-serif;
  padding: 5px;
  margin: 5px;
  border-radius: 2px;
  border: none;
  color: #f5f5f5; }
  button:focus {
    outline: none; }

.button-add, .button-generate, .button-nav {
  font-size: 1.2rem; }

.button-add, .button-nav {
  background-color: #003366; }
  .button-add:hover, .button-nav:hover {
    background-color: #03072b; }
  .button-add:disabled, .button-nav:disabled {
    background-color: #606060; }

.button-save, .button-generate {
  background-color: #006600; }
  .button-save:hover, .button-generate:hover {
    background-color: #004c00; }
  .button-save:disabled, .button-generate:disabled {
    background-color: #606060; }

.button-edit, .button-cancel, .button-shuffle {
  background-color: #cc5200; }
  .button-edit:hover, .button-cancel:hover, .button-shuffle:hover {
    background-color: #a65200; }

.button-delete {
  background-color: #800000; }
  .button-delete:hover {
    background-color: #660000; }

.button-shuffle {
  font-size: 1.2rem; }

.player-item {
  background-color: #003366;
  margin: 10px;
  width: 300px;
  border-radius: 5px;
  display: content-box;
  overflow: hidden; }

.player-name {
  color: #f5f5f5;
  padding-top: 7.5px; }

.tie {
  background-color: #003366;
  margin: 10px;
  width: 200px;
  height: 100px;
  border-radius: 5px;
  display: content-box;
  overflow: hidden;
  border: 2px solid #f5f5f5; }
  .tie p {
    color: #f5f5f5;
    padding: 7.5px; }
  .tie .match-vs {
    font-size: 1.2rem;
    background-color: #f5f5f5;
    color: #800000;
    padding: 0px; }
  .tie .player-tbc {
    visibility: hidden; }

.bye-label {
  background-color: #006600;
  border-bottom: 20px solid #f5f5f5; }

@media only screen and (min-width: 1091px) {
  .round-title {
    align-self: center;
    position: absolute;
    top: 215px; } }

.round-ties {
  justify-self: center; }
